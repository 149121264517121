.nav-element {
    flex: 1;
    text-align: center;
    height: 100%;
    width: 100%;
    display: inline-block;
}

.nav-element.active>button {
    color: white;
}

.nav-element>button {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: rgb(187, 187, 187);
    font-size: large;
    line-height: 56px;
}

.nav-element svg {
    vertical-align: baseline;
}

.footer .nav-element>button {
    font-size: x-large;
    line-height: 44px;
}

.is-desktop .socials-col {
    max-width: fit-content;
}