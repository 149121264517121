a:hover {
  background-color: gray;
}

.root-div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #000000;
  overflow: hidden;
}

.page-root {
  color: white;
  position: absolute;
  top: 56px;
  bottom: 45px;
  right: 0px;
  left: 0px;
  background-color: #222222;
  overflow: hidden;
}

.page-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1em;
  margin: 10px;
}

.scrollable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.page-container.is-desktop {
  column-gap: 2%;
  margin: 10px auto;
  max-width: 1000px;
  width: 90%;
}

.content-block {
  background-color: #515151;
  box-shadow: 2px 2px 2px #000000;
}

.full-width {
  flex-basis: 100%;
  position: relative;
}

.content-header {
  font-size: x-large;
  font-family: 'Inter', sans-serif;
}

.centered {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bucket-border {
  color: black;
  background-color: white;
  padding: 0.25rem;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

img,
svg {
  vertical-align: top;
}