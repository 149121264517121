.page-container.is-desktop .card-img-top.small-img {
    max-width: 33%;
    margin: auto;
    padding: .5em;
}

.page-container.is-mobile .card-img-top.small-img {
    max-height: 200px;
    object-fit: cover;
}

.card .light-background {
    background: white;
}

.update-element.card {
    width : 100%;
}

.basic-outline {
    text-shadow: 2px 2px #000000;
}