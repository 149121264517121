
.item {
    position: relative;
    flex-basis: 23%;
    max-width: 23%;
    box-shadow: 2px 2px 2px #000000;
}

.is-mobile .item {
    flex-basis: 29%;
    max-width: 33%;
    border-radius: .5rem;
}

.buffer {
    margin-top: 133%;
}

.is-mobile .buffer {
    margin-top: 100%;
}

.cover-art {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.is-mobile .cover-art {
    border-radius: .5rem;
}

.cover-art > img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
}

.game-title {

    color: white;
    text-align: center;
    text-shadow: 2px 2px #000000;
    min-width: 100%;
}

.is-desktop .game-title {
    position: relative;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.4rem;
    box-sizing: border-box;
    background-color: #363636;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.is-mobile .game-title {
    position: absolute;
    font-size: 1em;
}