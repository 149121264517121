.header-container {
    display: flex;
    height : 56px;
    font-size: x-large;
    text-align: center;
    position: absolute;
    top : 0px;
    width: 100%;
    background-color: #111111;
    border-bottom : 1px solid black;
}

.header-container.desktop > .header-logo {
    margin-left: 1em; 
    text-align: left;
}

.header-container.compact > .header-logo {
    flex: 4;
    text-align: center;
}

.header-container > .header-logo {
    position: relative;
    height : 100%;
    padding: 8px 0 8px 0;
    line-height: 40px;
    box-sizing: border-box;
}

.header-logo img{
    height : 40px;
    width: auto;
}

.header-logo > span {
    margin-left: 12px;
    font-family: 'Press Start 2P', cursive;
    font-size: 80%;
    top: 0;
}

.header-logo, .header-navbar {
    flex: 4;
    color :white;
}