.long-button {
    white-space: nowrap;
    line-height: 2em;
}

.is-mobile .long-button {
    line-height: 0;
}

.long-button svg {
    font-size: 2em;
}

.is-desktop .long-button svg {
    margin-left: 15px;
}

.sweet-font {
    font-family: 'Press Start 2P', cursive;
    text-shadow: 2px 2px #000000;
    font-size: medium;
    display: inline-block;
}

.nav-link {
    color: white;
}

img.contained-img {
    width: 100%;
    height: auto;
}